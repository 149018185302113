<template>
        <v-dialog
        v-model="isDialogVisible"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            color="secondary"
            outlined
            dark
            v-bind="attrs"
            v-on="on"
          >
          {{btnText}}
          </v-btn>
        </template>
        <v-card tile>
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="isDialogVisible = false"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
            <v-toolbar-title>Доступные марафоны</v-toolbar-title>
              <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <available-marathons></available-marathons>
          </v-card-text>
         </v-card>
      </v-dialog>
</template>

<script>
import AvailableMarathons from '../../views/AvailableMarathons.vue'
import store from '@/store'
import {
  mdiClose,
} from '@mdi/js'
import _ from 'lodash';

export default {
    props: [
    'btnText',
    ],
    components: {
      AvailableMarathons
    },
    data() {
        return {
            isDialogVisible: false,
            icons: {
                mdiClose,
            },
        }
    },
    computed: {
      marathons() {
        return _.orderBy(store.state.products.marathon, 'startDate.seconds', 'asc'); 
      }
    },
}
</script>
<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';
.v-card {
  height: 100%;
}

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>