<template>
  <div class="page-title text-center px-5" id="misc">
    <h2 class="text-2xl font-weight-semibold text--primary">
      Нет подписки на марафон
    </h2>
    <p class="text-sm">
      В настоящее время вы не подписаны ни на один марафон. Нажмите ниже, чтобы просмотреть доступные марафоны
    </p>
    <div class="misc-character d-flex justify-center mt-5 mb-5">
      <v-img
        max-width="700"
        src="@/assets/images/3d-characters/tanya-bean-bag.png"
      ></v-img>
    </div>
    <div class="d-flex align-center justify-center">
      <available-marathons-dialog btnText="Посмотреть марафоны"></available-marathons-dialog>
    </div>
  </div>
</template>

<script>
import AvailableMarathonsDialog from '../components/dialogs/AvailableMarathonsDialog.vue'

export default {
  components: {
    AvailableMarathonsDialog,
  },
  data () {
    return {
      loading: false,
    };
  },
}
</script>
<style lang="scss" scoped>
@import '@core/preset/preset/misc.scss';
@import '~@core/preset/preset/mixins.scss';
.v-card {
  height: 100%;
}

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  height: 100%;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>
