<template>
  <div>
    <template v-if="subscribedProducts">
      <div v-if="hasSubscriptions.any">
          <p class="text-2xl mt-8 mb-6">
            Программы
          </p>
          <v-row>
              <v-col 
              cols="12" lg="3"
              v-for="marathon in marathons"
              :key="marathon.id"
              v-if="isSubscribed(marathon.id)"
              >
                <product-list-view 
                :productId="marathon.id"
                :type="marathon.type"
                :name="marathon.name"
                :startDate="marathon.startDate.seconds"
                :prices="marathon.price"
                :imageName="marathon.imageName"
                :imagePath="marathon.imagePath"
                :subscription="isSubscribed(marathon.id)"
                ></product-list-view>        
              </v-col>
              <v-col 
              cols="12" lg="3"
              v-for="guide in guides"
              :key="guide.id"
              v-if="isSubscribed(guide.id)"
              >
                <product-list-view 
                :productId="guide.id"
                :name="guide.name"
                :price="guide.price"
                :type="guide.type"
                :downloadPath="guide.downloadPath"
                :prices="guide.price"
                :imageName="guide.imageName"
                :imagePath="guide.imagePath"
                :subscription="isSubscribed(guide.id)"
                ></product-list-view>        
              </v-col>
        </v-row>
      </div>
      <!-- <div class="d-flex align-center justify-center mt-10">
        <available-marathons-dialog btnText="Посмотреть все марафоны"></available-marathons-dialog>
      </div> -->
    </template>
    <template v-else>
    <div>
    <p class="text-2xl mt-8 mb-6">
      Программы
    </p>
    <v-row>
      <v-col 
      cols="12" lg="3"
      v-for="marathon in marathons"
      :key="marathon.id"
      >
        <product-list-view 
        :productId="marathon.id"
        :type="marathon.type"
        :name="marathon.name"
        :startDate="marathon.startDate.seconds"
        :prices="marathon.price"
        :imageName="marathon.imageName"
        :imagePath="marathon.imagePath"
        :subscription="isSubscribed(marathon.id)"
        ></product-list-view>        
      </v-col>
    </v-row>
    <p class="text-2xl mt-16 mb-6">
      Гайды
    </p>
        <v-row>
      <v-col 
      cols="12" lg="3"
      v-for="guide in guides"
      :key="guide.id"
      >
        <product-list-view 
        :productId="guide.id"
        :name="guide.name"
        :price="guide.price"
        :type="guide.type"
        :downloadPath="guide.downloadPath"
        :prices="guide.price"
        :imageName="guide.imageName"
        :imagePath="guide.imagePath"
        :subscription="isSubscribed(guide.id)"
        ></product-list-view>        
      </v-col>
    </v-row>
  </div>
    </template>
  </div>
</template>

<script>
import ProductListView from '../components/ProductListView'
import AvailableMarathonsDialog from '../components/dialogs/AvailableMarathonsDialog.vue'
import store from '@/store'
import {
  mdiClose,
} from '@mdi/js'
import _ from 'lodash';

export default {
    props: [
    'btnText',
    'subscribedProducts'
    ],
    components: {
      ProductListView,
      AvailableMarathonsDialog
    },
    data() {
        return {
            isDialogVisible: false,
            icons: {
                mdiClose,
            },
        }
    },
    computed: {
      marathons() {
        return _.orderBy(store.state.products.marathon, 'startDate.seconds', 'asc'); 
      },
      guides() {
        return _.orderBy(store.state.products.guide, 'createdAt.seconds', 'asc'); 
      },
      hasSubscriptions() {
        let subscriptionType = {};
        Object.keys(store.state.user.user.subscriptions).forEach(key => {
          subscriptionType[store.state.user.user.subscriptions[key].type] = true
        });
         if(Object.keys(store.state.user.user.subscriptions).length > 0) {
           subscriptionType.any = true
         } else {
           subscriptionType.any = false
         }
        return subscriptionType;
      },
    },
    methods: {
      isSubscribed(id) {
        if(store.state.user.user.subscriptions[id]) {
          return true
        } else {
          return false
        }
      }
    }
}
</script>
<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';
.v-card {
  height: 100%;
}

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>